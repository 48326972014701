@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Fugaz+One&family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

body {
  --chakra-fonts-body: 'Manrope', sans-serif;
  --chakra-colors-chakra-body-bg: var(--chakra-colors-bg); 
  --chakra-colors-chakra-body-text: var(--chakra-colors-white);
  --base-font-size: 16px;
  /* overscroll-behavior-y: contain; */
}

#root {
  max-width: 400px;
  margin: 0 auto;
  
}

.open-menu {
  overflow: hidden;
  background: black;
}


/* @keyframes slideUp {
  from {
    transform: translate(0, 90px);
  }
  to {
    transform: translate(0, 0);
  }
} */


@keyframes flashanim {
  0% {	color: var(--chakra-colors-white80);}   
  100% {	color: #1E93FF;}
}